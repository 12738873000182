//Money Formatter
export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'KES',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

//Check for input is number
export const isNumber = (value) => {
  const regExp = /^[0-9\b]+$/
  if (value === '' || regExp.test(value)) return true
  else return false
}

//Check for email format
export const isEmail = (value) => {
  const regExp = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
  if (regExp.test(value)) return true
  else return false
}

//Get tenancy details
export const getTenancyDetails = (tenancies, tenants, units, properties) => {
  let tenancyDetails = []
  tenancies.map((tenancy) => {
    let tenancyDetail = {}
    tenancyDetail.tenancyID = tenancy.id
    tenancyDetail.tenantID = tenancy.tenant_id
    tenancyDetail.unitID = tenancy.unit_id
    tenancyDetail.businessName = tenancy.business_name
    tenancyDetail.natureOfBusiness = tenancy.nature_of_business
    if (tenancy.business_name === 'RESIDENTIAL')
      tenancyDetail.tenancyType = 'RESIDENTIAL'
    else tenancyDetail.tenancyType = 'COMMERCIAL'
    tenancyDetail.entryDate = tenancy.entry_date
    tenancyDetail.exit_date = tenancy.exit_date
    tenancyDetail.isActive = tenancy.is_active
    tenancyDetail.depositCharged = tenancy.deposit_charged
    tenancyDetail.depositPaid = tenancy.deposit_paid
    tenancyDetail.lastBillDate = tenancy.last_bill_date
    tenants.map((tenant) => {
      if (tenant.id === tenancy.tenant_id) {
        tenancyDetail.tenantName = tenant.name
        tenancyDetail.tenantIDNumber = tenant.id_number
        tenancyDetail.tenantKraPIN = tenant.kra_pin
        tenancyDetail.tenantCertNo = tenant.cert_no
        tenancyDetail.tenantPhoneNo = tenant.phone_no
        tenancyDetail.tenantOccupation = tenant.occupation
        tenancyDetail.tenantEmail = tenant.email
        tenancyDetail.tenantWebsite = tenant.website
        tenancyDetail.tenantPostalAddress = tenant.postal_address
      }
    })
    units.map((unit) => {
      if (unit.id === tenancy.unit_id) {
        tenancyDetail.propertyID = unit.property_id
        tenancyDetail.unitLabel = unit.label
        tenancyDetail.unitDeposit = unit.deposit
        tenancyDetail.unitRent = unit.rent
        tenancyDetail.unitBillingCycle = unit.billing_cycle
        properties.map((property) => {
          if (property.id === unit.property_id) {
            tenancyDetail.propertyName = property.name
          }
        })
      }
    })
    tenancyDetails.push(tenancyDetail)
  })
  return tenancyDetails
}

//Prepare Property Tenancy Monthly Stats for posting
export const preparePropertyTenancyMonthlyStatsObject = (
  month,
  year,
  propertyID,
  tenantACEntries,
  allTenancies,
  allUnits
) => {
  //Set variables for targeted month start and end dates
  const firstDayofMonth = new Date(year, month - 1, 1)
  const lastDayofMonth = new Date(year, month, 0, 23, 59, 59)

  //Fetching entries for the month
  let monthEntries = []
  tenantACEntries.map((entry) => {
    if (
      new Date(entry.transaction_date) >= firstDayofMonth &&
      new Date(entry.transaction_date) <= lastDayofMonth
    ) {
      allTenancies.map((tenancy) => {
        if (entry.tenancy_id === tenancy.id) {
          allUnits.map((unit) => {
            if (
              tenancy.unit_id === unit.id &&
              unit.property_id === propertyID
            ) {
              monthEntries.push(entry)
            }
          })
        }
      })
    }
  })

  //Filtering out the repeated tenancy IDs
  let monthTenancyIDs = []
  monthEntries.map((entry) => {
    if (!monthTenancyIDs.includes(entry.tenancy_id)) {
      monthTenancyIDs.push(entry.tenancy_id)
    }
  })

  const tenancyMonthlyStat = {
    tenancyIDs: monthTenancyIDs,
    startDate: firstDayofMonth,
    endDate: lastDayofMonth,
    propertyID: propertyID,
    month: month,
    year: year,
  }

  return tenancyMonthlyStat
}

//Prepare Property Monthly Stats for posting
export const preparePropertyMonthlyStatsObject = (
  propertyID,
  monthlyTenancyStats
) => {
  let prepayments = 0
  let credits = 0
  let debits = 0
  let waivers = 0
  let deposits = 0
  let debtsPaid = 0
  let target = 0
  let overpayments = 0
  let monthDebts = 0
  let cumulativeBalance = 0
  let month = null
  let year = null

  //Complex algo to get property monthly details (Requires critical thinking)
  monthlyTenancyStats.map((stat) => {
    if (stat.balance_before < 0) {
      if (stat.debits + stat.deposits + stat.balance_before < 0)
        prepayments += stat.debits + stat.deposits
      else prepayments += 0 - stat.balance_before
      // Commented out when to cater for month balance
      //  prepayments += 0 - stat.balance_before
    }
    credits += stat.credits
    debits += stat.debits
    deposits += stat.deposits
    //Calculate waivers
    if (stat.waivers > 0) {
      if (stat.waivers <= stat.balance_before) waivers += 0
      else {
        if (stat.balance_before >= 0)
          //Check to ensure waiver is not added to negative balance before(Captures as a bug)
          waivers += stat.waivers - stat.balance_before
        else {
          waivers += stat.waivers
        }
      }
    }

    // debtsPaid += stat.debt_paid

    if (stat.balance_before > 0) {
      if (stat.waivers < stat.balance_before) {
        if (stat.month_total > 0) {
          if (stat.month_total > stat.balance_before) {
            debtsPaid += stat.balance_before - stat.waivers
          } else {
            if (stat.credits >= stat.balance_before) {
              debtsPaid += stat.balance_before - stat.waivers
            } else {
              debtsPaid += stat.credits
            }
          }
        } else {
          if (stat.credits >= stat.balance_before) {
            debtsPaid += stat.balance_before - stat.waivers
          } else {
            debtsPaid += stat.credits
          }
        }
      }
    }

    if (stat.balance_before >= 0) {
      target += stat.debits + stat.deposits
    } else {
      if (stat.debits + stat.deposits + stat.balance_before > 0)
        target += stat.debits + stat.deposits + stat.balance_before
    }

    overpayments += stat.overpayment
    //Additional overpayment if a prepayment existed
    // if (stat.month_total > 0) {
    //   if (stat.balance_before < 0) {
    //     if (stat.balance_before + stat.credits >= 0) {
    //       overpayments += 0 - stat.balance_before
    //     } else {
    //       overpayments += stat.credits
    //     }
    //   }
    // } set to be cpatured from tenancy stats

    //calculate month balance
    if (stat.month_total <= 0) {
      if (stat.balance_before < 0) {
        if (stat.debits + stat.deposits + stat.balance_before >= 0) {
          if (stat.month_total - stat.balance_before < 0)
            monthDebts += 0 - (stat.month_total - stat.balance_before)
          // else overpayments += stat.month_total - stat.balance_before //Overpayment whn month total was zero but prepayment existed
          //  set to be cpatured from tenancy stats
        }
      } else {
        monthDebts += 0 - (stat.month_total - stat.debt_paid)
      }
    } else {
      if (stat.balance_before > 0) {
        if (stat.month_total < stat.balance_before) {
          if (
            stat.balance_before - stat.month_total >
            stat.debits + stat.deposits
          )
            monthDebts += stat.debits + stat.deposits
          else monthDebts += stat.balance_before - stat.month_total
        }
      }
    }
    // console.log(`${stat.tenancy_id}, ${monthDebts}`)

    if (stat.balance_before - stat.month_total > 0) {
      cumulativeBalance += stat.balance_before - stat.month_total
    }

    month = stat.month
    year = stat.year
  })

  const propertyStat = {
    property_id: propertyID,
    prepayments: prepayments,
    credits: credits,
    debits: debits,
    waivers: waivers,
    deposits: deposits,
    debts_paid: debtsPaid,
    target: target,
    overpayments: overpayments,
    month_debts: monthDebts,
    cumulative_balance: cumulativeBalance,
    month: month,
    year: year,
  }
  return propertyStat
}

//Get property Units
export const getPropertyUnits = (propertyID, units) => {
  const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
  let unitLabelsArr = []
  let propertyUnits = []
  units.map((unit) => {
    if (unit.property_id === propertyID) {
      unitLabelsArr.push(unit.label)
      propertyUnits.push(unit)
    }
  })
  //Sorting alphanumeric
  const sortedUnitLabels = unitLabelsArr.sort(sortAlphaNum)
  let sortedUnits = []
  sortedUnitLabels.map((label) => {
    propertyUnits.map((unit) => {
      if (label === unit.label) sortedUnits.push(unit)
    })
  })
  return sortedUnits
}

//Get User/Group Role names
export const getRoleNames = (userRoles, roles) => {
  let userRoleNames = []
  userRoles.map((userRole) => {
    roles.map((role) => {
      if (userRole.role_id === role.id) {
        userRoleNames.push(role.name)
      }
    })
  })
  return userRoleNames
}
